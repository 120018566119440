<template>

  <div>
    <div class="title">
      我的信箱
    </div>
    <div class="main">
      <van-empty v-if="showEmpty" description="还没有任何消息哦，快去发送一条吧">
        <van-button round type="danger" to="/write" class="bottom-button" color="#00D477" >去发短信</van-button>
      </van-empty>


      <MailboxItem v-for="(item, index) in chatData"
                   :date="item.sendTime"
                   :phone="item.phone"
                   :replyCount="item.replyCount"
                   :smsStatus="item.smsStatus"
                   @click="toChat(index)"
                   :content="item.content"/>


    </div>
  </div>
</template>

<script>
import {Button,Empty } from 'vant';
import MailboxItem from "../../components/mailbox/MailboxItem";
import { getMailBoxList} from "../../api/sms";

export default {
  name: "mailbox",
  data() {
    return {
      clientHeight: '',
      showEmpty: false,
      chatData: []
    }
  },
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
    MailboxItem
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getMailBoxList()
          .then(res => {
            this.chatData = res.data.data
            if (this.chatData.length > 0){
              this.showEmpty = false
            }else {
              this.showEmpty = true
            }
          })
    },
    toChat(index) {

      this.$router.push({
        path: '/chat',
        query: {
          id: this.chatData[index].chatId,
          phone: this.chatData[index].phone,
        }
      })
    }
  }


}
</script>

<style scoped>

.main {
  background: #f7f7f7;
  /*padding: 0 15px ;*/
  padding: 0 15px 65px 15px;
  height: 100%;
  box-sizing: border-box;
}


.title {
  font-size: 22px;
  color: #292833;
  font-weight: bold;
  height: 40px;
  padding: 15px;
  line-height: 40px;
  z-index: 999;
  background: #f7f7f7;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.bottom-button {
  width: 160px;
  height: 40px;
}

</style>